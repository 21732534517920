@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Yatra+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


/* 
*{
  border: 1px black dashed;
} */


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif;
}

body {
  margin: 0 !important;
}

.logo{
  font-family:"Yatra One", system-ui;
  font-weight: 400;
  font-style: normal;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
  height:10px
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.modal-class::-webkit-scrollbar {
  width:0;
  scrollbar-color:transparent!important
}
.modal-class::-webkit-scrollbar {
  width: 1px;
  height: 1px; 
}
.modal-class::-webkit-scrollbar-thumb {
  background: transparent!important
}
.modal-class::-webkit-scrollbar-track {
  background: transparent!important
}



.stock-chart .highcharts-point-down {
  fill: #f23645;
  stroke: #f23645;
}

.stock-chart .highcharts-point-up {
  fill: #089981;
  stroke: #089981;
}

.MuiDataGrid-row{
  --rowBorderColor:#D1D5DB!important;
  background-color: white!important;
}

.MuiDataGrid-row:hover{
  background-color: #f6f5fa!important
}

.table-container{

}

/* @media screen and (min-width: 1200px) {
  .table-container {
    width: 850px !important;
  }
}
@media screen and (max-width: 1200px) {
  .table-container {
    width: 650px !important;
  }
} */

@media screen and (max-width: 1200px) and (min-width: 800px) {
  .table-container {
    width: 780px !important;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .table-container {
    width: 980px !important;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .table-container {
    width: 1100px !important;
  }
}
@media screen and (max-width: 1650px) and (min-width: 1500px) {
  .table-container {
    width: 1300px !important;
  }
}
@media screen and (max-width: 1750px) and (min-width: 1650px) {
  .table-container {
    width: 1400px !important;
  }
}


.MuiDataGrid-columnHeaders::after{
  background-color: transparent !important;
  height: 0px !important;
  display: none !important;
}



.MuiDataGrid-virtualScroller{
  background-color: transparent!important
}

