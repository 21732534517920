/* basic styles */
.skeleton {
    background: #ddd;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 4px;
  }
  .skeleton.text {
    width: 100%;
    height: 12px;
  }
  .skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
  }
  .skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .skeleton.thumbnail {
    width: 100px;
    height: 100px;
  }

  /* animation effects */
.shimmer-wrapper {
    position: absolute;
    /* parent must be relative position & overflow hidden */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2s infinite;
  }
  .shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.2);
  }
  .dark .shimmer {
    background: rgba(255,255,255,0.05);
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.05);
  }
  
  @keyframes loading {
    0% { transform: translateX(-150%) }
    50% { transform: translateX(-60%) }
    100% { transform: translateX(150%) }
  }